<template>
  <div id="mapContainer"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ29sZGVuamFndWFyIiwiYSI6ImNrcHc5NDJwOTI0ZzAycXM0Z2s2Z2ozbDQifQ.o4afOl96P3Jq3Pxsi8KeKw";

export default {
  data() {
    return {
      center: [33.9749, -118.1194],
      map: null,
      toggleIconLayer: false,
    };
  },
  methods: {
    setupMap: function () {
      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/dark-v10",
        center: [-118.1194, 34.0249],
        zoom: 10,
      });

      this.map.on("load", () => {
        this.map.addSource("heat", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        this.map.addSource("convex-points", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        this.map.addLayer(
          {
            id: "accidents-heat",
            type: "heatmap",
            source: "heat",
            maxzoom: 12,
            paint: {
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              "heatmap-intensity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                1,
                9,
                3,
              ],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(33,102,172,0)",
                0.2,
                "rgb(103,169,207)",
                0.4,
                "rgb(209,229,240)",
                0.6,
                "rgb(253,219,199)",
                0.8,
                "rgb(239,138,98)",
                1,
                "rgb(178,24,43)",
              ],
              // Adjust the heatmap radius by zoom level
              "heatmap-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                2,
                9,
                20,
              ],
              // Transition from heatmap to circle layer by zoom level
              "heatmap-opacity": [
                "interpolate",
                ["linear"],
                ["zoom"],
                8,
                1,
                13,
                0.5,
              ],
            },
          },
          "waterway-label"
        );

        this.map.addLayer({
          id: "polygon-layer",
          type: "fill",
          source: "convex-points",
          minzoom: 12,
          paint: {
            "fill-color": [
              "case",
              ["<", ["get", "probability"], 0.04],
              "#fed976",
              ["<", ["get", "probability"], 0.06],
              "#feb24c",
              ["<", ["get", "probability"], 0.08],
              "#fd8d3c",
              ["<", ["get", "probability"], 0.1],
              "#fc4e2a",
              // greater than or equals 0.10
              "#e31a1c",
            ],
            "fill-opacity": 0.5,
          },
          filter: ["==", "$type", "Polygon"],
        });
      });
    },
    updateMap: function (new_points) {
      const geojson = {};
      Object.keys(new_points).forEach((cluster_id) => {
        geojson[cluster_id] = [];
        new_points[cluster_id].points.forEach((element) => {
          geojson[cluster_id].push({
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [element[0], element[1]],
            },
          });
        });
      });

      let flatten_geojson = [];
      Object.keys(new_points).forEach((cluster_id) => {
        flatten_geojson = flatten_geojson.concat(geojson[cluster_id]);
      });

      this.map.getSource("heat").setData({
        type: "FeatureCollection",
        features: flatten_geojson,
      });

      const turf_polygons = [];
      Object.keys(new_points).forEach((cluster_id) => {
        const turf_points = [];
        new_points[cluster_id].points.forEach((single_point) => {
          turf_points.push(turf.point(single_point));
        });
        const polygon = turf.convex(turf.featureCollection(turf_points));
        polygon.properties["probability"] = new_points[cluster_id].probability;
        polygon.properties["cluster_id"] = cluster_id;
        turf_polygons.push(polygon);
      });

      this.map
        .getSource("convex-points")
        .setData(
          turf.polygonSmooth(
            turf.transformScale(turf.featureCollection(turf_polygons), 1.8),
            { iterations: 4 }
          )
        );

      this.map.on("click", "polygon-layer", (e) => {
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            "cluster_id: " +
              "<p>" +
              e.features[0].properties.cluster_id +
              "</p>" +
              "probability: " +
              "<p>" +
              (e.features[0].properties.probability * 100).toFixed(2) +
              "%" +
              "</p>"
          )
          .addTo(this.map);
      });
    },
    toggleRealtimeLayer: function (toggleMode) {
      this.fetchHistorical().then((data) => {
        this.addOrUpdateRealtimeLayer(data);

        this.map.setLayoutProperty(
          "comparison-layer",
          "visibility",
          toggleMode ? "visible" : "none"
        );
      });
    },
    fetchHistorical: function () {
      let predictionTime = this.selectedDate;
      if (predictionTime === null) {
        predictionTime = new Date();
        predictionTime.setHours(0, 0, 0, 0);
      }

      const promise = this.axios.get("/historical", {
        params: {
          date:
            predictionTime.getFullYear() +
            "-" +
            (predictionTime.getMonth() + 1 < 10
              ? "0" + (predictionTime.getMonth() + 1)
              : predictionTime.getMonth() + 1) +
            "-" +
            (predictionTime.getDate() < 10
              ? "0" + predictionTime.getDate()
              : predictionTime.getDate()),
          time:
            (predictionTime.getHours() < 10
              ? "0" + predictionTime.getHours()
              : predictionTime.getHours()) +
            ":" +
            (predictionTime.getMinutes() < 10
              ? "0" + predictionTime.getMinutes()
              : predictionTime.getMinutes()) +
            ":" +
            (predictionTime.getSeconds() < 10
              ? "0" + predictionTime.getSeconds()
              : predictionTime.getSeconds()),
        },
      });

      const dataPromise = promise.then((response) => response.data);

      return dataPromise;
    },
    addOrUpdateRealtimeLayer: function (incidentData) {
      incidentData = typeof incidentData === "undefined" ? {} : incidentData;

      let predictionTime = this.selectedDate;
      if (predictionTime === null) {
        predictionTime = new Date();
        predictionTime.setHours(0, 0, 0, 0);
      }

      if (this.map.getLayer("comparison-layer") === undefined) {
        this.map.addSource("comparison-layer", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: Object.keys(incidentData).map((incident_id) => {
              return {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "Point",
                  coordinates: [
                    incidentData[incident_id]["lng"],
                    incidentData[incident_id]["lat"],
                  ],
                },
              };
            }),
          },
        });

        this.map.addLayer({
          id: "comparison-layer",
          type: "circle",
          source: "comparison-layer",
          layout: {
            visibility: "none",
          },
          paint: {
            "circle-radius": 8,
            "circle-color": "#c2bf30",
          },
        });
      } else {
        this.map.getSource("comparison-layer").setData({
          type: "FeatureCollection",
          features: Object.keys(incidentData).map((incident_id) => {
            return {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [
                  incidentData[incident_id]["lng"],
                  incidentData[incident_id]["lat"],
                ],
              },
            };
          }),
        });
      }
    },
  },
  props: ["points", "selectedDate"],
  watch: {
    points: function (newVal) {
      this.updateMap(newVal);
    },
    selectedDate: function () {
      this.fetchHistorical().then((data) => {
        this.addOrUpdateRealtimeLayer(data);
      });
    },
  },
  mounted() {
    this.setupMap();
  },
};
</script>

<style scoped>
#mapContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}
</style>