<template>
  <v-app>
    <v-row class="mr-4 mt-3" justify="end">
      <v-btn
        id="metrics-btn"
        class="ml-4 btn"
        color="pink"
        @click="ontogglemetrics"
        dark
      >
        Metrics
      </v-btn>
      <v-btn
        id="incidents-btn"
        class="ml-4 btn"
        color="pink"
        @click="ontogglereal"
        dark
      >
        Recent Incidents
        <v-switch
          v-model="incidentSwitch"
          class="ml-4 pb-5"
          color="yellow"
          disabled
          hide-details
        ></v-switch>
      </v-btn>
      <v-btn
        id="toggle-btn"
        class="ml-4 btn"
        color="pink"
        @click="ontogglemenu"
        dark
      >
        Prediction
      </v-btn>
    </v-row>

    <div>
      <img src="./assets/turkaiLogo.png" />
    </div>

    <NewMap ref="mapComponent" :points="points" :selectedDate="selectedDate" />
    <Drawer
      ref="drawerComponent"
      @passData="passDataToMap($event)"
      @passTimeAndDate="passTimeAndDateToMap($event)"
    />
    <chart-dialog ref="chdialogComponent" />
  </v-app>
</template>

<script>
import Drawer from "./components/Drawer.vue";
import NewMap from "./components/NewMap.vue";
import ChartDialog from "./components/ChartDialog.vue";

export default {
  name: "App",

  components: {
    Drawer,
    NewMap,
    ChartDialog,
  },

  data() {
    return {
      incidentSwitch: false,
      points: [],
      selectedDate: null,
    };
  },
  methods: {
    ontogglemenu: function () {
      this.$refs.drawerComponent.toggleMenu();
    },
    passDataToMap: function (pts) {
      this.points = pts;
    },
    passTimeAndDateToMap: function (date) {
      this.selectedDate = date;
    },
    ontogglereal: function () {
      this.incidentSwitch = !this.incidentSwitch;
      this.$refs.mapComponent.toggleRealtimeLayer(this.incidentSwitch);
    },
    ontogglemetrics: function () {
      this.$refs.chdialogComponent.dialog = true;
    },
  },
};
</script>
<style scoped>
.btn {
  z-index: 1 !important;
}

img {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 5;
  height: 70px;
  width: 70px;
}
</style>