<template>
  <v-navigation-drawer width="400" v-model="drawer" absolute temporary>
    <div class="headline text-center pt-1"></div>

    <v-divider class="my-2"></v-divider>

    <!-- Date Picker -->
    <v-menu
      v-model="datepicker.menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="px-3"
          v-model="datepicker.date"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="datepicker.date"
        @input="
          sendTimeAndDate();
          datepicker.menu = false;
        "
      ></v-date-picker>
    </v-menu>

    <!-- Time Picker -->
    <v-menu
      ref="menu_timepicker"
      v-model="menu_timepicker"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="timepicker.time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="px-3"
          v-model="timepicker.time"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        :allowed-minutes="timepicker.allowed_minutes"
        :format="timepicker.format"
        v-if="menu_timepicker"
        v-model="timepicker.time"
        full-width
        scrollable
        @click:minute="
          $refs.menu_timepicker.save(timepicker.time);
          sendTimeAndDate();
        "
      ></v-time-picker>
    </v-menu>

    <v-row class="mr-4 my-3" justify="end">
      <v-btn
        v-on:click="postFilters"
        :disabled="isButtonDisabled"
        :loading="isButtonDisabled"
        color="pink"
        class="white--text"
        >Predict</v-btn
      >
    </v-row>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Feature</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in weather_features" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.feature }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      datepicker: {
        menu: false,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      timepicker: {
        time: "09:00",
        format: "24hr",
        allowed_minutes: [0, 15, 30, 45],
      },
      menu_timepicker: false,
      isButtonDisabled: false,
      weather_features: [
        {
          name: "Time",
          feature: "-",
        },
        {
          name: "Temperature",
          feature: "-",
        },
        {
          name: "Weather Description",
          feature: "-",
        },
        {
          name: "Wind Speed",
          feature: "-",
        },
        {
          name: "Precipitation",
          feature: "-",
        },
        {
          name: "Humidity",
          feature: "-",
        },
        {
          name: "Cloudcover",
          feature: "-",
        },
        {
          name: "Visibility",
          feature: "-",
        },
      ],
    };
  },
  methods: {
    postFilters: function () {
      let _this = this;
      _this.isButtonDisabled = true;

      let datetime_object = new Date(
        this.datepicker.date + " " + this.timepicker.time
      );

      _this.axios
        .get("/weather", {
          params: {
            datetime: datetime_object.toISOString().split(".")[0],
          },
        })
        .then((response) => {
          _this.axios
            .post("/submit", response.data, {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                type: "rf",
              },
            })
            .then(function (response) {
              var cluster_probs = {};
              for (const cluster_id in response.data) {
                cluster_probs[cluster_id] = response.data[cluster_id];
              }

              _this.axios
                .get("/cluster", {
                  params: {
                    cluster: Object.keys(cluster_probs),
                  },
                })
                .then(function (response) {
                  let cluster_points = response.data;

                  let clusters = {};
                  Object.keys(cluster_probs).forEach((cluster_id) => {
                    clusters[cluster_id] = {
                      probability: cluster_probs[cluster_id],
                      points: cluster_points[cluster_id],
                    };
                  });
                  _this.$emit("passData", clusters);
                })
                .catch(function (error) {
                  console.log(error);
                })
                .finally(function () {
                  _this.isButtonDisabled = false;
                  _this.drawer = false;
                });
            })
            .catch(function (error) {
              console.log(error);
            });
        });
    },
    toggleMenu: function () {
      this.drawer = !this.drawer;
    },
    sendTimeAndDate: function () {
      this.$emit(
        "passTimeAndDate",
        new Date(this.datepicker.date + "T" + this.timepicker.time)
      );

      let datetime_object = new Date(
        this.datepicker.date + " " + this.timepicker.time
      );

      this.axios
        .get("/weatherraw", {
          params: {
            datetime: datetime_object.toISOString().split(".")[0],
          },
        })
        .then((response) => {
          this.weather_features[0].feature = response.data.time.slice(5, -4);
          this.weather_features[1].feature =
            response.data.temperature + " \u2103";
          this.weather_features[2].feature =
            response.data.weatherdescriptions[0].split("'")[1];
          this.weather_features[3].feature = response.data.windspeed + " km/h";
          this.weather_features[4].feature =
            response.data.precipitation + " mm";
          this.weather_features[5].feature = response.data.humidity + "%";
          this.weather_features[6].feature = response.data.cloudcover + "%";
          this.weather_features[7].feature = response.data.visibility + " km";
        });
    },
  },
  mounted: function () {
    this.sendTimeAndDate();
  },
};
</script>

<style>
</style>