import Vue from "vue";
// axios
import VueAxios from "vue-axios";
import axios from "axios";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_URL,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.use(VueAxios, axiosIns);
export default axiosIns;
