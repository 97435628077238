import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  data() {
    return {};
  },
  mixins: [reactiveProp],
  props: ["options"],
  mounted: function () {
    this.renderChart(this.chartData, this.options);
  },
};
