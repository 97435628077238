<template>
  <!-- <div class="small">
    <line-chart :chart-data="datacollection"></line-chart>
    <button @click="updateData()">Randomize</button>
  </div> -->
  <v-dialog v-model="dialog" max-width="700px">
    <v-card>
      <line-chart
        class="mx-3"
        :chart-data="datacollection"
        :options="options"
        height="400"
        width="700"
      ></line-chart>

      <v-card-actions class="ma-0 pa-0">
        <v-radio-group
          v-model="chartradio"
          v-on:change="fetchChartData"
          class="mx-auto"
          mandatory
          dense
          row
        >
          <v-radio label="Random Forest" value="rf" color="pink"></v-radio>
          <v-radio
            label="Multi Layer Perceptron"
            value="mlp"
            color="pink"
          ></v-radio>
        </v-radio-group>
      </v-card-actions>

      <v-card-text class="pb-1 pt-2 font-weight-medium">
        Precision: Fraction of relevant instances among the retrieved
        instances<br />
        Recall: Fraction of relevant instances that were retrieved<br />
        F1: Harmonic mean of the precision and recall
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="pink darken-1" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LineChart from "./LineChart.js";

export default {
  components: { LineChart },
  data() {
    return {
      datacollection: {},
      options: { maintainAspectRatio: true },
      dialog: false,
      chartradio: "rf",
    };
  },
  methods: {
    fetchChartData: function () {
      let _this = this;
      this.axios
        .get("/chart", {
          params: {
            type: this.chartradio,
          },
        })
        .then(function (response) {
          let data_list = [];
          Object.keys(response.data).forEach(function (key) {
            data_list.push(response.data[key]);
          });
          _this.updateData(data_list);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    updateData: function (data) {
      let labels = [];
      let datasets = [];
      let data_f1 = [];
      let data_precision = [];
      let data_recall = [];
      data.forEach((d) => {
        labels.push(d["date"]);
        data_f1.push(d["f1"]);
        data_precision.push(d["precision"]);
        data_recall.push(d["recall"]);
      });
      datasets.push({
        label: "F1",
        borderColor: "#62021e",
        fill: false,
        data: data_f1,
      });
      datasets.push({
        label: "Precision",
        borderColor: "#e6a6c6",
        fill: false,
        data: data_precision,
      });
      datasets.push({
        label: "Recall",
        borderColor: "#c5558e",
        fill: false,
        data: data_recall,
      });

      this.datacollection = {
        labels: labels,
        datasets: datasets,
      };
    },
  },
  mounted: function () {
    this.fetchChartData();
  },
};
</script>

<style>
</style>